
import GeneralData from '../components/GeneralData.vue'
import useLocation from "@/modules/locations/composables/useLocation";
import toastr from "toastr";
import {ref} from "vue";
import router from "@/router";

export default {
    components: {GeneralData},
    setup() {
        const {clearForm, locationForm, createLocation, catalogs} = useLocation()
        const sending = ref(false)

        clearForm();

        return {
            sending,
            catalogs,
            cancel: () => router.push({name: 'locations'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createLocation(locationForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'locations'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
